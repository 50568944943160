import OrdersPage from '../../views/OrdersPage';



export default function Order() {
    return (<>
        <OrdersPage />
    </>);
}


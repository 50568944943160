import React from "react";

import LandingPage from '../../views/Landing';
import Footer from '../../views/Footer'


export default function Overview() {
  return (<>
      <LandingPage /> 
      {/* <Footer/> */}

      </>);
}

import HttpService from '../apis';

class DashboardServices extends HttpService {
  constructor(host = '') {
    super(host);
  }

  async getWidgetData(payLoad, token=null) {
    console.log("before await")
    const response = await this.post('assessment-widget', payLoad, token, 'AUTH');
    console.log("response",response)
    return response;
  };

  async getHistoryGrid(payLoad, token) {
    const response = await this.post('fp-history-grid', payLoad, token, 'AUTH');
    return response;
  };

  
}

export default new DashboardServices();
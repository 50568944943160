import apiConfing from './api.config';


class HttpService {

  constructor(client, auth) {
    try {
      this.client = client || apiConfing.client;
      this.auth = auth || apiConfing.auth;
    }
    catch (ex) {
      console.error("Unable to assign host", ex);
    }
  }
  // I will use this later 
  createLink(url, params) {
    return `${this.host}/${url}/${this.buildQueryParams(params)}`;
  }

  getHeaders() {
    const headers = {
      // 'x-api-key': 'SxiG3Jxlt07g4W3XzTlYym3kOq9xj4DwmGzfGXc0',
      'Content-Type': 'application/json'
    };
    return headers;
  }

  buildQueryParams(params) {
    return params ? '?' + Object.entries(params).map((pair) => pair.join('=')).join('&') : '';
  }

  request(url, options, type) {
    let apiUrl = '';
    if (type === 'CLIENT') {
      apiUrl = `${this.client}/${url}`;
    } else {
      apiUrl = `${this.auth}/${url}`;
    }

    const headers = this.getHeaders();
    const apiOptions = { ...options };
    // console.log("API Options :", apiOptions);
    return new Promise((resolve, reject) => fetch(apiUrl, apiOptions)
      .then((res) => res.json())
      .then((response) => {
        const {
          status, data, statusCode, body
        } = response;

        // Bullshit 2 Romve this
        if (status === 'SUCCESS') {
          resolve(response);
        }
        // Bullshit 3 Romve this
        if (status === 'ERROR') {
          reject(response);
        }

      }).catch((err) => reject(err)));
  }

  get(url, params) {
    const options = {
      method: 'GET',
    };
    const apiUrl = url + this.buildQueryParams(params);
    // console.log(apiUrl);
    return this.request(apiUrl, options);
  }

  post(url, payload, token = null, type) {
    const options = {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(payload),
    };

    // if (type === 'CLIENT') {
    //   options.headers.Token = token
    // }
    return this.request(url, options, type);
  }

  put(url, payload) {
    const options = {
      method: 'PUT',
      body: JSON.stringify(payload),
    };
    return this.request(url, options);
  }

  delete(url) {
    // TBD for deltes api if any (delete for api)
  }

  postBinary(url, payload) {
    const options = {
      method: 'POST',
      body: payload,
    };

    return this.request(url, options);
  }
}

export default HttpService;
import HttpService from '../apis';

class OrderServices extends HttpService {
  constructor(host = '') {
    super(host);
  }

  async getOrders(payLoad, token) {
    const response = await this.post('orders', payLoad, token, 'CLIENT');
    return response;
  };

  async getOrdersDetails(payLoad, token) {
    const response = await this.post('orders/orderdetail', payLoad, token, 'CLIENT');
    return response;
  };

  async getOrdersLifecycle(payLoad, token) {
    const response = await this.post('orders/orderdetail/lifecycle', payLoad, token, 'CLIENT');
    return response;
  };
}

export default new OrderServices();
import React from "react";
import { useForm } from "react-hook-form";
import { Grid, Container } from "@material-ui/core";
import EmojiEventsTwoToneIcon from '@material-ui/icons/EmojiEventsTwoTone';
import ListAltTwoToneIcon from '@material-ui/icons/ListAltTwoTone';
import TuneTwoToneIcon from '@material-ui/icons/TuneTwoTone';

import TrendingUpTwoToneIcon from '@material-ui/icons/TrendingUpTwoTone';


import particles2 from "../../assets/images/hero-bg/particles-1.svg";
import OverviewHeader from "./LandingHeader.js";

import LoginForm  from './LoginForm'
import Register from './LogingRegister'
import LoginConfirm from './LoginConfirm'


const LoginTest=()=>{

  alert("Login fired ")
}

export default function LandingPage() {
  return (
    <>
      <div className="hero-wrapper bg-composed-wrapper bg-second">
        <Container>
          <OverviewHeader />
        </Container>
        <div className="hero-wrapper--content">
          <div className="bg-composed-wrapper--image opacity-5" />
          <div className="bg-composed-wrapper--bg bg-deep-sky opacity-6" />
          <div className="bg-composed-wrapper--bg bg-sunrise-purple opacity-6" />
          <div className="bg-composed-wrapper--image opacity-9" style={{ backgroundImage: "url(" + particles2 + ")" }}  />
          <div className="bg-composed-wrapper--content">
            <Container className="z-over shadow-container-content-5 text-white text-center pt-5">
              <Grid item md={11} lg={10} xl={8} className="mx-auto py-3 py-lg-5">
                <div className="py-4 mb-4">
                 <LoginForm/>
                 {/* <Register/> */}
                 {/* <LoginConfirm/> */}
                </div>
              </Grid>

              {/* box are for login page  */}
     
              {/* box area for login page  end here */}
            </Container>


            <div className="shadow-container-blocks-5 z-below">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path
                  fill="var(--light)"
                  fillOpacity="1"
                  d="M0,32L120,58.7C240,85,480,139,720,138.7C960,139,1200,85,1320,58.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

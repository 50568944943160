const isLoggedReducer = (state = false, action) => {
    switch (action.type) {
        case 'SIGN_IN':
            return { state: true, ...action }
        case 'SIGNED_OUT':
            return state = false
        default:
            return state;

    }

}

export default isLoggedReducer;
import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { ThemeProvider } from '@material-ui/styles';

// Layout Blueprints

import {
  LeftSidebar,
  CollapsedSidebar,
  MinimalLayout,
  PresentationLayout,
} from "./layout-blueprints";


// const Overview = import();

import Landing from "./shared-components/Landing";
import OrdersPage from './shared-components/Orders';
import OrdersDetailsPage from './shared-components/OrderDetails';

const Routes = () => {
  const location = useLocation();

  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <Route path={["/login"]}>
        <Switch location={location} key={location.pathname}>
          <Route path="/login" component={Landing} />
        </Switch>
      </Route>
      <Route
        path={[
          '/dashboard'
        ]}>
        <LeftSidebar>
          <Switch location={location} key={location.pathname}>
            <Route
              path="/dashboard"
              component={OrdersPage}
            />
            
          </Switch>
        </LeftSidebar>
      </Route>





    </Switch>
  );
};

export default Routes;

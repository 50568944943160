import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import dashboardServices from "../../services/dashboard-services";
import clsx from "clsx";

import OrdersTable from "./OrderTable";

import {
  Grid,
  Card,
  List,
  ListItem,
  Button,
  CardContent,
  Paper,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrdersPage = () => {
  const [activeTab, setActiveTab] = useState("ACTIVE");
  const [widgetsCount, setWidgetsCount] = useState({});
  const [gridData, setGridData] = useState({});

  const token = useSelector((state) => {
    console.log("ddd :", state.AuthReducer.payload);
    return state.AuthReducer.payload;
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    fetchWidgetCount(tab);
  };

  useEffect(() => {
    fetchWidgetCount("ACTIVE");
  }, []);

  const fetchWidgetCount = (tabId) => {
    const payLoad = {
      org_name: "igpl-29395",
    };

    dashboardServices.getWidgetData(payLoad).then((data) => {
      console.log("data get w :", data);
      setWidgetsCount(data.data);
    });

    dashboardServices.getHistoryGrid(payLoad).then((data) => {
      console.log("grid Data", data.data.good);
      setGridData(data.data);
    });


  };

  return (
    <>
      {/* <PageTitle
        titleHeading="Order List"
        titleDescription="Here you will see list of Orders !"
      /> */}

      <Grid container spacing={3} style={{ marginBottom: "20px" }}>
        <Grid item xs>
          <Card className="card-box bg-premium-dark border-0 text-light">
            <CardContent>
              <div className="align-box-row align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Good Files Count of the day 
                  </small>
                  <span className="font-size-xxl mt-1">{widgetsCount.good}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-success font-size-xl d-50 rounded-circle btn-icon">
                    <FontAwesomeIcon icon={["far", "building"]} />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <FontAwesomeIcon
                  icon={["fas", "arrow-up"]}
                  className="text-success"
                />
                <span className="text-success px-1">15.4%</span>
                <span className="text-white-50">increase this month</span>
              </div>
            </CardContent>
          </Card>
        </Grid>
       
        <Grid item xs>
          <Card className="card-box bg-midnight-bloom text-white">
            <CardContent>
              <div className="align-box-row align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                    Processed Files Count of the day 
                  </small>
                  <span className="font-size-xxl mt-1">{widgetsCount.processed}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-danger font-size-xl d-50 rounded-circle btn-icon">
                    <FontAwesomeIcon icon={["far", "keyboard"]} />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <FontAwesomeIcon
                  icon={["fas", "arrow-up"]}
                  className="text-warning"
                />
                <span className="text-warning px-1">4.2%</span>
                <span className="text-white-50">lower order numbers</span>
              </div>
            </CardContent>
          </Card>
        </Grid>



        <Grid item xs>
          <Card className="card-box bg-night-sky text-light">
            <CardContent>
              <div className="align-box-row align-items-start">
                <div className="font-weight-bold">
                  <small className="text-white-50 d-block mb-1 text-uppercase">
                  Bad Files Count of the day 
                  </small>
                  <span className="font-size-xxl mt-1">{widgetsCount.bad}</span>
                </div>
                <div className="ml-auto">
                  <div className="bg-white text-center text-primary font-size-xl d-50 rounded-circle btn-icon">
                    <FontAwesomeIcon icon={["far", "dot-circle"]} />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <FontAwesomeIcon
                  icon={["fas", "arrow-up"]}
                  className="text-success"
                />
                <span className="text-success px-1">12.65%</span>
                <span className="text-white-50">same as before</span>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid>
        <Card className=" p-3">
          <div className="card-header-alt d-flex justify-content-between p-4">
            <div>
              <h5 className="font-weight-bold  text-black">
                Current Status of files
              </h5>
              {/* <p class="text-black-50 mb-0">Carousel component built with React. It is a React port of Slick carousel.</p> */}
            </div>
            <div className="d-flex1 align-items-center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(
                    "https://app.powerbi.com/groups/1428c8a4-89e6-4e27-a0af-92037ea19369/reports/accc4809[…]9e919718b42f77c4905?bookmarkGuid=Bookmarkb965138f0b42e57e4d51",
                    "Aurion PowerBI Report",
                    "toolbar=no,scrollbars=yes,resizable=yes,top=300,left=50, width=1224,height=860"
                  );
                }}
              >
                {" "}
                Click here to view Error Analytics{" "}
              </Button>
            </div>
          </div>

          <List className="nav-tabs nav-tabs-primary d-flex">
            <ListItem
              button
              disableRipple
              selected={activeTab === "ACTIVE"}
              onClick={() => {
                toggle("ACTIVE");
              }}
            >
              Good Files
            </ListItem>
            <ListItem
              button
              disableRipple
              selected={activeTab === "COMPLETED"}
              onClick={() => {
                toggle("COMPLETED");
              }}
            >
              Processed Files
            </ListItem>
            <ListItem
              button
              disableRipple
              selected={activeTab === "CANCELLED"}
              onClick={() => {
                toggle("CANCELLED");
              }}
            >
              Bad Files
            </ListItem>
          </List>

          <div
            className={clsx("tab-item-wrapper", {
              active: activeTab === "ACTIVE",
            })}
            index={0}
          >
            <div className="text-left ">
            <OrdersTable data={gridData.good} />
            </div>
          </div>

          <div
            className={clsx("tab-item-wrapper", {
              active: activeTab === "COMPLETED",
            })}
            index={1}
          >
            <div className="text-left ">
              <OrdersTable data={gridData.processed} />
            </div>
          </div>

          <div
            className={clsx("tab-item-wrapper", {
              active: activeTab === "CANCELLED",
            })}
            index={2}
          >
            <div className="text-left ">
              <OrdersTable data={gridData.bad} />
            </div>
          </div>
        </Card>
      </Grid>
    </>
  );
};

export default OrdersPage;

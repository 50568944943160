import React, { use } from "react";
import { useForm } from "react-hook-form";
import loginService from "../../services/login-services";
import { useSelector, useDispatch } from "react-redux";
import { signInAction } from "../../actions";
import { Redirect, useHistory } from "react-router-dom";
import people3 from "../../assets/images/stock-photos/people-3.jpg";
import { Grid, TextField, Button } from "@material-ui/core";
import axios from "axios";
import CryptoJS from "crypto-js";
import dotenv from "dotenv";
import crypto from "crypto";

dotenv.config();

function formSubmit(e) {
  e.preventDefault();
  var secrectKey = "12345";
  var body = new FormData();
  body.append("userName", "test");
  body.append("password", "AMIT HERE ");
  body.append("user_type", 1);

  var ciphertext = CryptoJS.AES.encrypt("my message", secrectKey).toString();

  console.log("cipertext : ", ciphertext);

  var bytes = CryptoJS.AES.decrypt(ciphertext, secrectKey);
  console.log("bytest : ", bytes);

  var originalText = bytes.toString(CryptoJS.enc.Utf8);

  console.log("originalText  : ", originalText); // 'my message'

  // axios({
  //   method: "post",
  //   url: "http://demo.com/api/v1/end-user/login",
  //   data: body,
  //   config: { headers: { "Content-Type": "multipart/form-data" } },
  // })
  //   .then(function (response) {
  //     //handle success
  //     console.log(response);
  //   })
  //   .catch(function (response) {
  //     //handle error
  //     console.log(response);
  //   });

  alert("form submit called ");
}

const algorithm = "aes-256-cbc";
const key = crypto.randomBytes(32);
const iv = crypto.randomBytes(16);

function encrypt(text) {
  let cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return { iv: iv.toString("hex"), encryptedData: encrypted.toString("hex") };
}

function decrypt(text) {
  let iv = Buffer.from(text.iv, "hex");
  let encryptedText = Buffer.from(text.encryptedData, "hex");
  let decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}

export default function LoginForm() {
  let history = useHistory();
  const dispatch = useDispatch();

  const secrectKey = "12345";
  const { register, handleSubmit, watch, errors } = useForm();

  const onSubmit = (data) => {
    let newPayload = {
      username: data.username,
      password: CryptoJS.AES.encrypt(data.password, secrectKey).toString(),
    };

    console.log("newPayload.password, : ", newPayload.password);
    console.log("typeo of encryp", typeof newPayload.password);

    var bytes = CryptoJS.AES.decrypt(newPayload.password, secrectKey);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);

    console.log("bytes  :", bytes);
    console.log("originalText  : ", originalText); // 'my message'

    // new api call for
    fetch(
      "https://jx4arpn1y7.execute-api.ap-southeast-2.amazonaws.com/ipv_dev/user/login",
      {
        method: "POST", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newPayload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data.IdToken);
        dispatch(signInAction(data.IdToken));
        history.push("/dashboard");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    // loginService
    //   .login(newPayload)
    //   .then((data) => {
    //     alert("login service ");
    //     console.log("login action withou toekn : ", data);
    //     dispatch(signInAction(data.data.id_token));
    //     history.push("/dashboard");
    //   })
    //   .catch((e) => {
    //     console.log(e.message);
    //   });
  }; // your form submit function which will invoke after successful validation

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <form onSubmit={formSubmit}> */}
        <div className="hero-wrapper bg-composed-wrapper bg-skim-blue h-100 rounded-top">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div
              className="bg-composed-wrapper--image rounded-top opacity-4"
              style={{ backgroundImage: "url(" + people3 + ")" }}
            />
            <div className="bg-composed-wrapper--bg bg-second opacity-4 rounded-top" />
            <div className="bg-composed-wrapper--content text-center ">
              <div className="text-white">
                <h1 className="display-3 my-3 font-weight-bold">Login</h1>
                <p className="font-size-lg mb-0 text-white-50">
                  Fill in the fields below to login to your account
                </p>
              </div>
              <div className="shape-container-top-1" style={{ margin: 0 }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                  <path
                    fill="var(--white)"
                    fillOpacity="1"
                    d="M0,288L48,250.7C96,213,192,139,288,106.7C384,75,480,85,576,112C672,139,768,181,864,176C960,171,1056,117,1152,128C1248,139,1344,213,1392,250.7L1440,288L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div
          className="bg-white rounded pt-4 pt-lg-0"
          style={{ marginTop: "-100px", textAlign: "left", color: "black" }}
        >
          <Grid
            item
            lg={10}
            xl={9}
            className="z-over pt-5 pt-lg-4 pb-4 mx-auto"
          >
            <div className="px-4 py-5">
              <div className="mb-3">
                <label className="font-weight-bold mb-2">Email address</label>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="yourname@yourmail.com"
                  type="username"
                  name="username"
                  inputRef={register}
                />
              </div>
              <div className="mb-4">
                <div className="d-flex justify-content-between">
                  <label className="font-weight-bold mb-2">Password</label>
                  <a href="#/" onClick={(e) => e.preventDefault()}>
                    Forgot password?
                  </a>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Enter your password"
                  type="password"
                  name="password"
                  inputRef={register}
                />
              </div>
              <Button
                size="large"
                type="submit"
                fullWidth
                className="text-uppercase font-weight-bold font-size-sm btn-primary"
              >
                Sign in
              </Button>
            </div>
          </Grid>
        </div>
      </form>
    </>
  );
}

import HttpService from "../apis";

class LoginService extends HttpService {
  constructor(host = "") {
    super(host);
  }

  async register(payLoad) {
    const response = await this.post("orders", payLoad, "AUTH");
    return response;
  }

  async validate(payLoad) {
    const response = await this.post("orders/orderdetail", payLoad, "AUTH");
    return response;
  }

  async login(payLoad) {
    const response = await this.post("user/login", payLoad, "AUTH");
    return response;
  }
}

export default new LoginService();

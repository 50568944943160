import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Collapse,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
  List,
  ListItem
} from '@material-ui/core';

import projectLogo from '../../assets/images/icon-visual-data-1.png';
import { NavLink } from 'react-router-dom';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export default function LandingHeader
() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [collapse, setCollapse] = useState(false);
  const toggle = () => setCollapse(!collapse);

  return (
    <>
      <div className="header-nav-wrapper header-nav-wrapper-lg navbar-dark">
        <div className="app-nav-logo">
          <NavLink
            to="/Login"
            title="iclearchain"
            className="app-nav-logo ">
            <div className="app-nav-logo--icon ">
              <img
                alt="iclearchain"
                src={projectLogo}
              />
            </div>
            <div className="app-nav-logo--text">
              <span>AURION's</span>
              <b>iProVisualiser</b>
            </div>
          </NavLink>
        </div>
   
      </div>
     
    </>
  );
}

import React from "react";
import MaterialTable from "material-table";
import { NavLink, Link } from 'react-router-dom';

// import { loadCSS } from "fg-loadcss"
import { Button,} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Visibility,CloudDownload} from "@material-ui/icons";
import apiConfing from '../../apis/api.config';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "10px",
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    background: "#e1f5fe",
  },
  countStyle: {
    color: "#01579b",
  },
  popover: {
    width: "325px",
    minWidth: "16px",
    minHeight: "16px",
    overflowX: "hidden",
    overflowY: "auto",
  },
}));

export default function OrderTable(props) {

  console.log("props inside table -", props)
  const classes = useStyles();
  
  const getTableOptions = () => {
    return {
      exportButton: true,
      search: true,
      paging: true,
      padding: "dense",
      headerStyle: {
        color: "#FFF",
        backgroundColor: "#253053",
      },
      actionsColumnIndex: 0,
      actionsCellStyle: {
        paddingRight: "20px"
      },
      // pageSize: 10
    };
  };

  // const getViewRrportBtn = (Text, icon) => () => {
  //   return (<Button color="primary" startIcon={icon} variant="contained"> {Text} </Button>);
  // }

//   <Link
//   href={compareReportsService.createLink('downloadmissingmscsdatabycompkey', { compKey })}
//   onClick={compKey}>
//   <Tooltip title={`Click here to downlaod Total Interval Data Difference`}>
//     <IconButton color='primary' className={classes.removePadding} >
//       <CloudDownload></CloudDownload>
//     </IconButton>
//   </Tooltip>
// </Link>

  const getTableActions = () => {
    return [
      (row) => ({
        // icon: () =><NavLink  href="/order-details" data={row}> <Visibility style={{ color: '#4caf50', }} ></Visibility></NavLink>,
        icon: () =><a  href={apiConfing.auth+'/download-bucket-file?org_name=igpl-29395&key='+encodeURIComponent(row.filename)} >  <CloudDownload>style={{ color: '#4caf50', }} ></CloudDownload> </a>,
        // onClick: row.tdnmi == 0 ? (e) => e.preventDefault() : 'activateSecondTab',
      }),
   ]
  };

  const getTableColumns = () => {
    return [
      {
        title: "File Name ",
        field: "filename",
        cellStyle: {
          width: '600px'
        },

      },
      {
        title: "Date",
        field: "date",
      },
      
    ];
  };

  return (
    <MaterialTable
      title="Good Files List "
      columns={getTableColumns()}
      data={props.data}
      actions={getTableActions()}
      options={getTableOptions()}
    />
  );
}
